body {
  margin: 0;
  font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(207, 204, 204);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
}

/* CSS START */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.profile-image {
  border-radius: 50%;
  height: 175px;
  width: 175px;
  margin-bottom: 30px;
}

a {
  color: white;
}

a:hover {
  color: rgb(211, 211, 211);
}


header {
  display: flex;
  height: 75px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  background-color: #4c4e4d;

}

h1 {
  margin: 30px 0;
}

h2 {
  color: rgb(248, 248, 248);
  font-size: 3vw;
  padding-left: 40px;
}


.nav-menu {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 45px;
  justify-content: end;
  margin: 0;
}

section {
  padding: 0 6rem;
  margin: 30px;
}

.title-style {
  color: rgb(192, 76, 33)
}

.greeting {
  font-size: 3.5rem;
  margin-bottom: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.occupation {
  width: auto;
  overflow: hidden;
  font-size: 1.5rem;
  color: rgb(192, 76, 33);
  border-right: .2em solid rgb(238, 155, 2);
  white-space: nowrap;
  margin: 0 auto;
  display: inline-block;
  animation:
    typing 1.75s steps(40, end),
    blink-caret .75s step-end infinite;
}

.skills {
  list-style: none;
}

.resume-small {
  display: none;
}


@keyframes typing {
  from {
    max-width: 0
  }

  to {
    max-width: 100%
  }
}

@keyframes blink-caret {

  from,
  to {
    border-color: transparent
  }

  50% {
    border-color: rgb(238, 155, 2);
  }
}

.profile-img-container {
  display: grid;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  justify-items: end;
  margin: 50px 0;
}

.description {
  padding-left: 50px;
}

.projects {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card {
  width: 35vw;
  margin-bottom: 50px;
  background-color: rgb(77, 76, 78);
  color: white;
  -webkit-box-shadow: 6px 7px 17px -3px rgba(68,68,68,0.81); 
  box-shadow: 6px 7px 17px -3px rgba(68,68,68,0.81);
}

.card-body h4 {
  font-size: 2.4vw;
  font-weight: bold;
}

.card-body p {
  font-size: 1.2vw;
  text-align: center;
  padding-bottom: 0.5rem;
}

.card-body span {
  font-size: 1vw;
  font-style: italic;
}

.card-img {
  width: 100%;
  height: 50%;
  object-fit: fill;
}


.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px;
}

.button-container a{
  font-size: 1.5vw;
  display: flex;
  flex-direction: column;
}

.navActive {
  background-color: rgb(255, 127, 80);
  color: #000;
}

.navActive:hover {
  color: #4c4e4d;
}

.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

form {
  width: 50vw;
  margin: 40px auto;
}

label {
  font-size: 1.3rem;
}

footer {
  position: sticky;
  top: 100%;
  padding-bottom: 10px;
  text-align: center;
}

.contact {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  padding-top: 10px;
  margin-bottom: 8px;
  justify-content: center;
}

.contact-header {
  font-weight: bolder;
  color: rgb(192, 76, 33);
}

.fa {
  height: 40px;
  width: 40px;
  fill: #000;
  transition: transform .2s;
}

.fa:hover {
  filter: invert(.5);
  transform: scale(1.1);
}

.resume {
  padding-top: 2rem;
  text-align: center;
}

.download {
  color: rgb(192, 76, 33);
  font-size: 1.2rem;
}

.proficiencies {
  display: flex;
  flex-direction: row;
  gap: 7rem;
  justify-content: center;
  padding-top: 3rem;
}

.project-status {
  margin-top: 0.2rem;
  padding: 0 0.3rem;
  border-radius: 5px;
}

.completed {
  background: #30b830;
}

.incomplete {
  background: #f73737;
}


@media screen and (max-width: 980px) {
  header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 120px;
  }
  h2 {
    font-size: 4.5vw;
    margin-top: 10px;
  }

  nav {
    font-size: 2.3vw;
  }

  .nav-menu {
    width: 100%;
    display: flex;
    justify-content: center;
    grid-gap: 30px;
  }

  .profile-img-container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    justify-items: center;
    margin: 50px 0;
  }

  .greeting {
    font-size: 5vw;
  }

  .card {
    width: 40vw;
  }

  .projects {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
    justify-items: center;
  }

}


@media screen and (max-width: 768px) {
  header {
    height: 130px;
  }
  h2 {
    font-size: 5.2vw;
    margin-top: 10px;
  }

  nav {
    font-size: 4vw;
  }

  .nav-menu {
    width: 100%;
    display: flex;
    justify-content: center;
    grid-gap: 30px;
  }
  
  .profile-img-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 50px 0;
  }
  .about {
    padding: 0 2em;
    margin: 30px;
  }

  .portfolio {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 35px auto;
  }

  .card {
    width: 35vw;
  }

  .card h4 {
    margin-bottom: .35rem;
  }

  .card-body p {
    font-size: 1.2vw;
    padding-bottom: 0;
    margin-bottom: .7rem;
    text-align: center;
  }
  
  .card-body span {
    font-style: italic;
    padding-bottom: 0.2rem;
  }

  .projects {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
    justify-items: center;
  }

}

@media screen and (max-width: 575px) {
  header {
    height: 125px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  h2 {
    font-size: 8vw;
    margin-top: 10px;
    padding-bottom: 5px;
  }

  nav {
    font-size: 4vw;
  }

  .profile-img-container {
    padding-right: 65px;
  }

  .nav-menu {
    width: 100%;
    display: flex;
    grid-gap: 20px;
  }

  .greeting {
    font-size: 6vw;
  }

  .occupation {
    font-size: 3.7vw;
  }

  .projects {
    display: flex;
    flex-direction: column;
  }

  .card {
    width: 80vw;
  }

  .card-body h4 {
    font-size: 3vw;
  }
  
  .card-body p {
    font-size: 1.8vw;
    padding-bottom: 0;
    margin-bottom: .7rem;
  }
  
  .card-body span {
    font-size: 1.5vw;
    padding-bottom: 0.2rem;
  }

}

@media screen and (max-width: 500px) {
  header {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .profile-img-container {
    padding-right: 50px;
  }

  nav {
    font-size: 4vw;
  }

  .nav-menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .greeting {
    font-size: 6vw;
  }

  .occupation {
    font-size: 3.7vw;
  }

  .projects {
    display: flex;
    flex-direction: column;
  }

  .card-body h4 {
    font-size: 4.5vw;
  }
  
  .card-body p {
    font-size: 2.7vw;
  }
  
  .card-body span {
    font-size: 2.3vw;
  }

  .button-container a{
    font-size: 2.5vw;
    display: flex;
    flex-direction: column;
  }
  .btn {
    width: 6rem;
  }

  .resume {
    display: none;
    width: 100%;
  }

  .resume-small {
    display: block;
    text-align: center;
    padding: 0;
  }

  .proficiencies-small {
    padding-top: 1.2rem;
  }

}

@media screen and (max-width: 400px) {
  h2 {
    margin-left: 0;
    padding-left: 20px;
  }


  .resume-small h1{
    font-size: 1.7rem;
  }
  
  .resume-small h3{
    font-size: 1.2rem;
    font-weight: bold;
  }

  .resume-small ul li {
    line-height: 1.5;
  }

  .download {
    font-size: .9rem;
  }

}

@media screen and (max-width: 400px) {
  .card h4 {
    font-size: 4.5vw;
  }

  .card-body p {
    font-size: 2.8vw;
  }
  
  .card-body span {
    font-size: 3vw;
  }

  .btn {
    width: 4rem;
  }

}